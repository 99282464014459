import { InferGetStaticPropsType } from 'next';
import NextImage from 'next/legacy/image';
import Link from 'next/link';
import {
  Text,
  Box,
  Flex,
  Button,
  LinkBox,
  LinkOverlay,
  Stack,
  Center,
  AspectRatio,
  HStack,
} from '@chakra-ui/react';

import { LogCategories, sendClickLog } from 'libs/gtag';
import { layouts } from 'constants/layouts';
import { blogs } from 'constants/links';
import { paths } from 'constants/paths';
import DefaultHead from 'components/DefaultHead';
import ResponsiveButton from 'components/Shared/ResponsiveButton';
import Cases from 'components/Top/Cases';
import ServiceDescriptions from 'components/Top/ServiceDescriptions';

import TopHeroBackground from 'public/images/top_hero_bg.png';
import TopKeyVisualSP from 'public/images/top_key_visual_sp.png';
import TopHeroTitle from 'public/images/top_hero_title.svg';
import TopCustomerCount from 'public/images/top_customer_count.svg';
import TopCustomerSatisfaction from 'public/images/top_customer_satisfaction.svg';
import CustomerVoiceIcon from 'public/icons/customer_voice.svg';
import PackageIcon from 'public/icons/package_icon.svg';
import MagnifyingGlassIcon from 'public/icons/magnifying_glass_icon.svg';
import FlagIcon from 'public/icons/flag_icon.svg';
import PackageItemList from 'public/images/package_item_list.png';
import PackageItemListSP from 'public/images/package_item_list_sp.png';
import CostDownImage from 'public/images/cost_down_image.svg';
import ImprovementImage from 'public/images/improvement_image.svg';
import AllAtOnceImage from 'public/images/all_at_once_image.svg';
import ShizaiProImage from 'public/images/shizai_pro_image.svg';
import SustainableImage from 'public/images/sustainable_image.svg';
import LookForPackageImage from 'public/images/look_for_package_image.svg';
import ContactExpertImage from 'public/images/contact_expert_illustration.svg';
import TopButton from 'components/Top/TopButton';
import { getArticles } from 'libs/microcms';
import CustomerLogo from 'components/Logo/CustomerLogo';

const CUSTOMER_VOICE_COUNT = 4;

export const getStaticProps = async () => {
  const { contents: articles } = await getArticles({
    limit: CUSTOMER_VOICE_COUNT,
    orders: '-publishedAt',
    filters: 'isFeatured[equals]true',
  });

  return {
    props: {
      layout: layouts.default,
      articles,
    },
  };
};

const SectionSpacer = () => (
  <Box
    mt={{
      base: '4.5rem',
      md: '4.5rem',
      lg: '7.5rem',
    }}
  />
);

const descriptionImageWidth = { base: '100%', md: '100%', lg: '496px' };

const CUSTOMER_IMAGES = [
  {
    image: CustomerLogo.Asahi,
    name: 'アサヒ飲料株式会社',
    figmaPcWidthPx: 88,
    figmaSpWidthPx: 53,
    actualSize: { width: 89, height: 33 },
  },
  {
    image: CustomerLogo.Orion,
    name: 'オリオンビール株式会社',
    figmaPcWidthPx: 85,
    figmaSpWidthPx: 51,
    actualSize: { width: 86, height: 33 },
  },
  {
    image: CustomerLogo.Pal,
    name: '株式会社パル',
    figmaPcWidthPx: 96,
    figmaSpWidthPx: 58,
    actualSize: { width: 97, height: 29 },
  },
  {
    image: CustomerLogo.Zenb,
    name: '株式会社ZENB JAPAN',
    figmaPcWidthPx: 133,
    figmaSpWidthPx: 79,
    actualSize: { width: 134, height: 29 },
  },
  {
    image: CustomerLogo.Seiko,
    name: 'セイコーウォッチ株式会社',
    figmaPcWidthPx: 147,
    figmaSpWidthPx: 87,
    actualSize: { width: 148, height: 29 },
  },
  {
    image: CustomerLogo.SeibuSogo,
    name: '株式会社そごう・西武',
    figmaPcWidthPx: 226,
    figmaSpWidthPx: 134,
    actualSize: { width: 227, height: 29 },
  },
  {
    image: CustomerLogo.Seino,
    name: 'セイノーホールディングス株式会社',
    figmaPcWidthPx: 74,
    figmaSpWidthPx: 44,
    actualSize: { width: 1634, height: 1251 },
  },
  {
    image: CustomerLogo.Shibusawa,
    name: '澁澤倉庫株式会社',
    figmaPcWidthPx: 166,
    figmaSpWidthPx: 99,
    actualSize: { width: 99, height: 17 },
  },
  {
    image: CustomerLogo.Tential,
    name: '株式会社TENTIAL',
    figmaPcWidthPx: 158,
    figmaSpWidthPx: 93,
    actualSize: { width: 158, height: 28 },
  },
  {
    image: CustomerLogo.FabricTokyo,
    name: '株式会社FABRIC TOKYO',
    figmaPcWidthPx: 115,
    figmaSpWidthPx: 69,
    actualSize: { width: 115, height: 32 },
  },
  {
    image: CustomerLogo.TreeOfLife,
    name: '株式会社生活の木',
    figmaPcWidthPx: 128,
    figmaSpWidthPx: 76,
    actualSize: { width: 2048, height: 515 },
  },
  {
    image: CustomerLogo.ApaHotel,
    name: 'アパグループ株式会社',
    figmaPcWidthPx: 197,
    figmaSpWidthPx: 117,
    actualSize: { width: 197, height: 32 },
  },
  {
    image: CustomerLogo.TsuchiyaKaban,
    name: '株式会社土屋鞄製造所',
    figmaPcWidthPx: 221,
    figmaSpWidthPx: 140,
    actualSize: { width: 222, height: 29 },
  },
  {
    image: CustomerLogo.PostCoffee,
    name: 'POST COFFEE株式会社',
    figmaPcWidthPx: 180,
    figmaSpWidthPx: 105,
    actualSize: { width: 1415, height: 230 },
  },
  {
    image: CustomerLogo.Minimal,
    name: '株式会社βace(Minimal)',
    figmaPcWidthPx: 159,
    figmaSpWidthPx: 95,
    actualSize: { width: 160, height: 29 },
  },
  {
    image: CustomerLogo.Sparty,
    name: '株式会社Sparty',
    figmaPcWidthPx: 100,
    figmaSpWidthPx: 60,
    actualSize: { width: 101, height: 29 },
  },
  {
    image: CustomerLogo.HeartRelation,
    name: '株式会社heart relation',
    figmaPcWidthPx: 75,
    figmaSpWidthPx: 44,
    actualSize: { width: 76, height: 45 },
  },
];

const FIGMA_SP_WIDTH_PX = 375;
const MOBILE_MAX_WIDTH_PX = 512;

const Index = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <Box overflowX={{ base: 'hidden', md: 'hidden', lg: 'initial' }}>
      <DefaultHead
        title="shizai - あらゆる資材のコスト・仕様を最適化"
        url={process.env.ORIGIN + paths.root}
      />
      <Box position="relative" height={{ base: 'fit-content', md: 'fit-content', lg: '668px' }}>
        <Box
          id="pc-top-hero-background-image"
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          width={1750}
          height={708}
          display={{
            base: 'none',
            md: 'none',
            lg: 'block',
          }}
        >
          <AspectRatio
            ratio={1750 / 708}
            overflowX="hidden"
            minWidth={1750}
            maxWidth={1750}
            width={1750}
            height={708}
            minHeight={708}
            maxHeight={708}
          >
            <NextImage src={TopHeroBackground} layout="fixed" />
          </AspectRatio>
        </Box>
        <Flex
          top={0}
          left="50%"
          transform="translateX(-50%)"
          position={{ base: 'relative', md: 'relative', lg: 'absolute' }}
          overflowX="hidden"
          maxWidth="100vw"
          width="100vw"
          direction="row"
          height="fit-content"
          justify="center"
          align="flex-start"
        >
          <Flex
            as="section"
            justify="start"
            align="center"
            direction="column"
            height={{
              base: 'fit-content',
              md: 'fit-content',
              lg: 668,
            }}
            maxWidth="100%"
            mt={{ base: '0.75rem', md: '0.75rem', lg: '4rem' }}
          >
            <Text color="textGray.87" fontSize={{ base: 'sm', md: 'sm', lg: '2xl' }} align="center">
              全国数百以上の一次製造工場ネットワークで
            </Text>
            <Box
              display={{
                base: 'none',
                md: 'none',
                lg: 'block',
              }}
              mt="1.5rem"
            >
              <NextImage
                src={TopHeroTitle}
                width={680}
                height={169}
                layout="fixed"
                alt="あらゆる資材のコスト・仕様を最適化"
                title="あらゆる資材のコスト・仕様を最適化"
                priority
              />
            </Box>
            <AspectRatio
              mt={2}
              ratio={556 / 326}
              overflowX="hidden"
              minWidth="556px"
              maxWidth="1024px"
              width="100vw"
              display={{
                base: 'block',
                md: 'block',
                lg: 'none',
              }}
            >
              <NextImage src={TopKeyVisualSP} layout="fill" priority alt="スマホ向けヒーロー画像" />
            </AspectRatio>
            <Box mt={{ base: 2, md: 2, lg: 6 }} />
            <ServiceDescriptions />
            <Box
              paddingX={{ base: '1.5rem', md: '1.5rem', lg: 0 }}
              width={{ base: '100%', md: '100%', lg: 'auto' }}
              mt={{ base: 6, md: 6, lg: 8 }}
              maxW={{
                base: '512px',
                md: '512px',
                lg: 'auto',
              }}
            >
              <TopButton />
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Flex
        direction="column"
        gap={{ base: '4rem', md: '4rem', lg: '7.5rem' }}
        mt={{ base: 12, md: 12, lg: 0 }}
      >
        <Flex
          as="section"
          direction="column"
          justify="center"
          align="center"
          gap={{ base: 4, md: 4, lg: 6 }}
        >
          <Flex justify="center" align="center" gap={{ base: 3, md: 3, lg: 6 }}>
            <AspectRatio
              ratio={272 / 114}
              minW={148}
              maxW={272}
              width={{
                base: '40vw',
                md: '40vw',
                lg: 272,
              }}
            >
              <NextImage
                src={TopCustomerCount}
                priority
                alt="ご利用企業数700社突破 ※2024年6月時点"
              />
            </AspectRatio>

            <AspectRatio
              ratio={238 / 114}
              minWidth={128}
              maxWidth={238}
              width={{
                base: '34vw',
                md: '34vw',
                lg: 238,
              }}
            >
              <NextImage
                src={TopCustomerSatisfaction}
                priority
                alt="顧客満足度約95% ※2021年自社調査"
              />
            </AspectRatio>
          </Flex>

          <Flex direction="column" justify="center" align="center" gap={1}>
            <Text textStyle="h2" fontSize={{ base: '0.875rem', md: '0.875rem', lg: 34 }}>
              EC・D2Cだけではなく、メーカーや物流倉庫まで
            </Text>
            <Text color="textGray.60" fontSize={{ base: '0.75rem', md: '0.75rem', lg: 20 }}>
              幅広いお客さまにご利用いただいています
            </Text>
          </Flex>

          <HStack
            px={{
              base: 6,
              md: 6,
              lg: 0,
            }}
            width="100%"
            maxW={{
              base: 512,
              md: 512,
              lg: '64rem',
            }}
            flexWrap="wrap"
            columnGap={{
              base: 6,
              md: 6,
              lg: '36px',
            }}
            rowGap={{
              base: 4,
              md: 4,
              lg: '32px',
            }}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            alignSelf="center"
            spacing={0}
          >
            {CUSTOMER_IMAGES.map((customerImage, i) => {
              // (FigmaのSPアイコンの横幅 / FigmaのSPの横幅) * 100でview widthを算出
              const mobileViewWidthPx = (customerImage.figmaSpWidthPx / FIGMA_SP_WIDTH_PX) * 100;

              const mobileWidth = MOBILE_MAX_WIDTH_PX * (mobileViewWidthPx / 100);
              const mobileMaxWidth = `${mobileViewWidthPx}vw`;
              return (
                <AspectRatio
                  key={customerImage.name}
                  ratio={customerImage.actualSize.width / customerImage.actualSize.height}
                  width={{
                    base: `${mobileWidth}px`,
                    md: `${mobileWidth}px`,
                    lg: `${customerImage.figmaPcWidthPx}px`,
                  }}
                  maxWidth={{
                    base: mobileMaxWidth,
                    md: mobileMaxWidth,
                    lg: `${customerImage.figmaPcWidthPx}px`,
                  }}
                  order={i + 1}
                >
                  <NextImage src={customerImage.image} alt={customerImage.name} />
                </AspectRatio>
              );
            })}
          </HStack>
        </Flex>

        <Flex as="section" direction="column" gap={8} px={{ base: 6, md: 6, lg: 0 }}>
          <Flex justifyContent="center" alignItems="center" gap={2}>
            <Box as="span" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <NextImage
                src={CustomerVoiceIcon}
                width={64}
                height={64}
                layout="fixed"
                alt="お客さまの声アイコン"
              />
            </Box>
            <Text as="h2" textStyle="h1" mt={-2}>
              お客さまの声
            </Text>
          </Flex>
          <Box
            maxW={{
              base: '512px',
              md: '100%',
              lg: 'auto',
            }}
          >
            <Cases articles={props.articles} />
          </Box>
          <Center>
            <LinkBox
              onClick={() => {
                sendClickLog({
                  category: LogCategories.TOP,
                  label: '導入実績をもっと見る',
                });
              }}
              width={{ base: '100%', md: '100%', lg: 'auto' }}
              maxW={{
                base: '512px',
                md: '512px',
                lg: 'auto',
              }}
            >
              <LinkOverlay href={paths.blog}>
                <ResponsiveButton
                  variant="outline"
                  width={{
                    base: '100%',
                    md: '100%',
                    lg: 'auto',
                  }}
                >
                  もっと見る
                </ResponsiveButton>
              </LinkOverlay>
            </LinkBox>
          </Center>
        </Flex>
      </Flex>

      <Box
        as="section"
        marginX={{ base: 'auto', md: 'auto', lg: 0 }}
        paddingX={{ base: '1.5rem', md: '1.5rem', lg: 0 }}
        maxWidth={{
          base: '512px',
          md: '512px',
          lg: '100%',
        }}
      >
        <Box mt="1.5rem" />
        <SectionSpacer />

        <Flex justify="center" align="center" direction="column">
          <Flex>
            <Box mr="1rem" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <NextImage src={PackageIcon} width={64} height={64} layout="fixed" />
            </Box>
            <Text as="h2" textStyle="h1">
              豊富な対応資材
            </Text>
          </Flex>
          <Text
            textStyle="body1"
            color="textGray.60"
            fontSize={{
              base: '0.75rem',
              md: '0.75rem',
              lg: '0.875rem',
            }}
            align={{
              base: 'center',
              md: 'center',
              lg: 'start',
            }}
            mt={{
              base: '1rem',
              md: '1rem',
              lg: '1.5rem',
            }}
            whiteSpace={{
              base: 'pre-wrap',
              md: 'pre-wrap',
              lg: 'nowrap',
            }}
          >
            {`箱・袋・カード・包装紙・紙管など、
            あらゆる材質・デザイン・形状・加工に対応できます。`}
          </Text>
        </Flex>
        <Box
          margin="1.5rem 0"
          display={{
            base: 'block',
            md: 'block',
            lg: 'none',
          }}
        >
          <AspectRatio ratio={327 / 267} maxWidth="100%" margin="auto">
            <NextImage src={PackageItemListSP} layout="fill" alt="対応資材リスト" />
          </AspectRatio>
        </Box>
        <Box
          position="relative"
          height="248px"
          marginY="4px"
          display={{
            base: 'none',
            md: 'none',
            lg: 'block',
          }}
        >
          <Flex
            justify="center"
            position="absolute"
            width="100vw"
            maxWidth="100vw"
            overflowX="hidden"
            top={0}
            left="50%"
            transform="translateX(-50%)"
          >
            <Box>
              <NextImage
                src={PackageItemList}
                width={1756}
                height={272}
                layout="fixed"
                alt="対応資材リスト"
              />
            </Box>
          </Flex>
        </Box>
        <Center flexDirection="column">
          <Text
            as="p"
            textStyle="h4"
            align="center"
            fontSize={{
              base: '0.875rem',
              md: '0.875rem',
              lg: '1rem',
            }}
            whiteSpace={{
              base: 'normal',
              md: 'normal',
              lg: 'pre-wrap',
            }}
          >
            {`無地だけではなく、貴社のオリジナルデザインを印刷することも可能です。
            製造は1,000ロットから、無料でお見積もりとサンプルをお出しします。`}
          </Text>
          <Box
            mt={{
              base: '1.5rem',
              md: '1.5rem',
              lg: '2rem',
            }}
          />
          <Stack
            direction={{ base: 'column', md: 'column', lg: 'row' }}
            align="center"
            justify="center"
            spacing={{ base: '1rem', md: '1rem', lg: '2rem' }}
            width="100%"
          >
            <LinkBox
              onClick={() => {
                sendClickLog({
                  category: LogCategories.TOP,
                  label: '資材一覧へ',
                });
              }}
              width={{ base: '100%', md: '100%', lg: '320px' }}
            >
              <Link href={paths.quotation} passHref>
                <ResponsiveButton variant="outline" w="full">
                  資材一覧へ
                </ResponsiveButton>
              </Link>
            </LinkBox>
          </Stack>
        </Center>

        <SectionSpacer />

        <Flex justify="center" align="center" direction="column">
          <Flex>
            <Box mr="1rem" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <NextImage src={MagnifyingGlassIcon} width={64} height={64} layout="fixed" />
            </Box>
            <Text as="h2" textStyle="h1">
              選ばれる理由
            </Text>
          </Flex>
        </Flex>
        <Box mt={{ base: '1.5rem', md: '1.5rem', lg: '2rem' }} />
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row-reverse' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={62 / 45}>
            <NextImage
              src={CostDownImage}
              layout="fill"
              alt="アパレル企業のオリジナルダンボールを置き換えた事例"
            />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              数百以上の一次製造工場を熟知
              <br />
              最適な設備を持つ工場選定し
              <br />
              平均20%のコストダウンを実現
            </Text>
            <Box mt="1.5rem" />
            <Text
              as="p"
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              工場ごとに得意・不得意が存在し、同じ仕様でも工場によって価格に差が出ることはご存知ですか？
              <br />
              shizaiは全国の優良な一次製造工場の設備状況を熟知しています。製造工程や配送形態も踏まえ、貴社の資材に最適な工場を選定し、品質は落とさずにコストダウンを実現します。
            </Text>
          </Stack>
        </Stack>
        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={2}>
            <NextImage src={ImprovementImage} layout="fill" alt="改善のイメージ" />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              サイズ調整での配送費削減など
              <br />
              多角的な視点での改善提案
            </Text>
            <Box mt="1.5rem" />
            <Text
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              配送費や組み立て工数を削減できる仕様のご提案など、資材単体ではなく資材に関わるすべての工程を考慮し、改善提案を行います。
            </Text>
          </Stack>
        </Stack>
        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row-reverse' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={31 / 15}>
            <NextImage
              src={AllAtOnceImage}
              layout="fill"
              alt="shizaiのプロフェッショナルな人のイメージ"
            />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              豊富な専門知識で
              <br />
              貴社のイメージを形にします
            </Text>
            <Box mt="1.5rem" />
            <Text
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              プロの迅速なサポートで、作成の手間を最小に。
            </Text>
          </Stack>
        </Stack>

        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />

        <SectionSpacer />

        <Flex justify="center" align="center" direction="column">
          <Flex>
            <Box mr="1rem" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <NextImage src={FlagIcon} width={64} height={64} layout="fixed" />
            </Box>
            <Text as="h2" textStyle="h1">
              shizaiの取り組み
            </Text>
          </Flex>
          <Text
            textStyle="body1"
            color="textGray.60"
            fontSize={{
              base: '0.75rem',
              md: '0.75rem',
              lg: '0.875rem',
            }}
            align={{
              base: 'center',
              md: 'center',
              lg: 'start',
            }}
            mt={{
              base: '1rem',
              md: '1rem',
              lg: '1.5rem',
            }}
            whiteSpace={{
              base: 'pre-wrap',
              md: 'pre-wrap',
              lg: 'nowrap',
            }}
          >
            {`shizaiは資材業界のDXと
            サステナブル社会実現の両輪を目指しています`}
          </Text>
        </Flex>

        <Box mt={{ base: '1.5rem', md: '1.5rem', lg: '2rem' }} />

        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row-reverse' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={263 / 143}>
            <NextImage src={ShizaiProImage} layout="fill" alt="shizai proのサービス画面イメージ" />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              資材管理のデジタル化
            </Text>
            <Box mt="1.5rem" />
            <Text
              as="p"
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              shizaiは、アナログ業務が多い資材業界のDXを目指し、独自の発注管理ツール「shizai
              pro」を開発しています。
              <br />
              shizai
              proを活用することで、資材や商品の発注に関わる作業・管理コストが大幅に削減され、物流や資材の改善業務など、より本質的な業務に専念できるようになります。
            </Text>
            <Box mt="1.5rem" />
            <Flex
              flexDirection="column"
              width={{ base: '100%', md: '100%', lg: 'inherit' }}
              alignItems={{ base: 'stretch', md: 'stretch', lg: 'start' }}
            >
              <LinkBox
                onClick={() => {
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: 'shizai proの詳細を知りたい',
                  });
                }}
              >
                <LinkOverlay href="https://forms.gle/8UPwY2B1cnnP7sHR8" isExternal>
                  <Button
                    variant="outline"
                    height={{
                      base: '40px',
                      md: '40px',
                      lg: '3rem',
                    }}
                    w={{ base: '100%', md: '100%' }}
                    maxW="100%"
                    minWidth="initial"
                  >
                    shizai proの詳細を知りたい
                  </Button>
                </LinkOverlay>
              </LinkBox>
            </Flex>
          </Stack>
        </Stack>

        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />

        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={31 / 15}>
            <NextImage src={SustainableImage} layout="fill" alt="サステナブルな資材のイメージ" />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              サステナブル社会実現への投資
            </Text>
            <Box mt="1.5rem" />
            <Text
              textStyle="body1"
              color="textGray.60"
              width={descriptionImageWidth}
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              製造で消費した木を自然に戻すため、植樹活動の支援を行っています。その他、環境に優しいインキの使用なども行っています。
            </Text>
            <Box mt="1.5rem" />
            <Flex
              flexDirection="column"
              width={{ base: '100%', md: '100%', lg: 'inherit' }}
              alignItems={{ base: 'stretch', md: 'stretch', lg: 'start' }}
            >
              <LinkBox
                onClick={() => {
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: 'サステナブルパッケージについて',
                  });
                }}
              >
                <LinkOverlay href={blogs.sustainable} isExternal>
                  <Button
                    variant="outline"
                    height={{
                      base: '40px',
                      md: '40px',
                      lg: '3rem',
                    }}
                    w={{ base: '100%', md: '100%' }}
                    maxW="100%"
                    minWidth="initial"
                  >
                    サステナブルパッケージについて
                  </Button>
                </LinkOverlay>
              </LinkBox>
            </Flex>
          </Stack>
        </Stack>

        <SectionSpacer />

        <Center flexDirection="column">
          <Text as="h2" textStyle="h1" align={{ base: 'center', md: 'center', lg: 'start' }}>
            shizaiでムダなコストを削減しよう
          </Text>
          <Box mt={{ base: '2rem', md: '2rem', lg: '3rem' }} />
          <Stack
            direction="row"
            spacing={{ base: 0, md: 0, lg: '7rem' }}
            width={{ base: '100%', md: '100%', lg: 'auto' }}
          >
            <Center flexDirection="column" display={{ base: 'none', md: 'none', lg: 'flex' }}>
              <NextImage
                src={LookForPackageImage}
                width={400}
                height={376}
                alt="取り扱い資材を見てみる"
              />
              <Box mt="1rem" />
              <Text>取り扱い資材を見てみる</Text>
              <Box mt="2rem" />
              <LinkBox
                onClick={() =>
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: '資材一覧へ',
                    value: 'consultation',
                  })
                }
              >
                <Link href={paths.quotation} passHref>
                  <Button height="4.5rem" variant="outline">
                    <Flex direction="column">
                      <Text>資材一覧へ</Text>
                    </Flex>
                  </Button>
                </Link>
              </LinkBox>
            </Center>
            <Center flexDirection="column" width={{ base: '100%', md: '100%', lg: 'auto' }}>
              <AspectRatio ratio={223 / 181} width={{ base: '100%', md: '100%', lg: 464 }}>
                <NextImage
                  src={ContactExpertImage}
                  layout="fill"
                  alt="shizaiでできることをもっと知りたい"
                />
              </AspectRatio>
              <Box mt="1rem" />
              <Text
                display={{
                  base: 'none',
                  md: 'none',
                  lg: 'block',
                }}
              >
                shizaiでできることをもっと知りたい
              </Text>
              <Box mt="2rem" />

              <LinkBox
                onClick={() =>
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: '資料ダウンロード',
                    value: 'download_service_document',
                  })
                }
                width={{
                  base: '100%',
                  md: '100%',
                  lg: 'auto',
                }}
              >
                <Link href={paths.inquiryServiceDocument} passHref>
                  <ResponsiveButton width={{ base: '100%', md: '100%', lg: '320px' }}>
                    <Flex direction="column">
                      <Text>資料ダウンロード</Text>
                    </Flex>
                  </ResponsiveButton>
                </Link>
              </LinkBox>
            </Center>
          </Stack>
        </Center>
      </Box>
      <Box mt="4rem" />
    </Box>
  );
};

export default Index;
