import ApaHotel from 'public/images/customers/apa-hotel.svg';
import Asahi from 'public/images/customers/asahi.svg';
import FabricTokyo from 'public/images/customers/fabric-tokyo.svg';
import HeartRelation from 'public/images/customers/heart-relation.svg';
import Minimal from 'public/images/customers/minimal.svg';
import Orion from 'public/images/customers/orion.svg';
import Pal from 'public/images/customers/pal.svg';
import PostCoffee from 'public/images/customers/postcoffee.png';
import SeibuSogo from 'public/images/customers/seibu-sogo.svg';
import Seiko from 'public/images/customers/seiko.svg';
import Seino from 'public/images/customers/seino.png';
import Shibusawa from 'public/images/customers/shibusawa.svg';
import Sparty from 'public/images/customers/sparty.svg';
import Tential from 'public/images/customers/tential.svg';
import TreeOfLife from 'public/images/customers/tree-of-life.png';
import TsuchiyaKaban from 'public/images/customers/tsuchiya-kaban.svg';
import Zenb from 'public/images/customers/zenb.svg';

const CustomerLogo = {
  ApaHotel,
  Asahi,
  FabricTokyo,
  HeartRelation,
  Minimal,
  Orion,
  Pal,
  PostCoffee,
  SeibuSogo,
  Seiko,
  Seino,
  Shibusawa,
  Sparty,
  Tential,
  TreeOfLife,
  TsuchiyaKaban,
  Zenb,
};

export default CustomerLogo;
